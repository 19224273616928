<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col
        xl="4"
        md="6"
      >
        <rubisco-riepilogo-carrello :data="data_crm.carrello" />
      </b-col>
      <b-col
        xl="8"
        md="6"
      >
        <rubisco-statistiche :data="data_crm.statisticsItems" />
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="4">
        <rubisco-contatti />
      </b-col>

      <b-col lg="8">
        <b-card>
          <h4 class="pb-2">Video Tutorial Uso del CRM</h4>

          <div
            v-for="(riga,index) in data_crm.video"
            :key="index"
            class="pb-3"
          >
            <h5 class="mb-0">{{ riga.titolo }}</h5>
            <b-embed type="video" controls>
              <source :src="riga.link" type="video/mp4">
            </b-embed>
          </div>
        </b-card>

      </b-col>
    </b-row>
    
  </section>
</template>

<script>
import { BRow, BCol, BCard, BCardText, BEmbed } from 'bootstrap-vue'
import RubiscoRiepilogoCarrello from './RubiscoRiepilogoCarrello.vue'
import RubiscoStatistiche from './RubiscoStatistiche.vue'
import RubiscoContatti from './RubiscoContatti.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard, 
    BCardText, 
    BEmbed, 

    RubiscoRiepilogoCarrello,
    RubiscoStatistiche,
    RubiscoContatti, 
  },
  data() {
    return {
      userData: {},
      data_crm: {
        carrello: { },
        statisticsItems: [],
      },
    }
  },
  created() {
    // dati reali
    this.userData = JSON.parse(localStorage.getItem('userData'))

    this.$http.get('v2/azienda/scrivaniaInfo/'+this.userData.id_azienda+'/'+this.userData.id_azienda_padre)
      .then(response => {
        this.data_crm = response.data

        this.data_crm.carrello.name = this.userData.fullName || this.userData.username

        console.log(response.data_crm);
      })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
</style>
